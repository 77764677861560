<template>
  <v-content>
    <v-dialog v-model="confirmdialog" persistent>
      <v-card class="ticket_dialogue text-center">
        <v-card-title class="body-2 maincolor-bg justify-center">
          本当に使用しますか？
        </v-card-title>
        <div class="text-center pa-5">
          <v-btn rounded class="maincolor-bg default_button" @click.stop="use()">使用する</v-btn>
          <br><br>
          <v-btn rounded class="caption font-weight-black maincolor-btn2" @click="confirmdialog = false">キャンセルする</v-btn>
        </div>
      </v-card>
    </v-dialog>
  </v-content>
</template>

<script>
export default {
  props: ['item', 'init'],

  data: () => ({
      confirmdialog: false,
      ticket_id: null,
  }),
  methods: {
      use() {
          this.confirmdialog = false
          this.$emit('use')
      },
      open(id) {
        this.ticket_id = id
        this.confirmdialog = true
      },
      close() {
        this.confirmdialog = false
      },
      submit() {
      },
      clear() {
      },
      UseTicket(id) {
        this.ticket_id = id
      }
  },
}
</script>
